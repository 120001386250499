import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { formatDistanceToNowStrict } from 'date-fns';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import AvatarGroup from '@mui/material/AvatarGroup';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
// routes

// api
//
import { fDate } from 'src/utils/format-time';

// ----------------------------------------------------------------------

export default function ScreeningNavItem({ selected, collapse, item, index, setScreeningId }) {
  function buildPlainText(richText) {
    return richText.replace(/<[^>]*>/g, '');
  }

  return (
    <ListItemButton
      disableGutters
      onClick={() => setScreeningId(index)}
      sx={{
        py: 1.5,
        px: 2.5,
        ...(selected && {
          bgcolor: 'action.selected',
        }),
      }}
    >
      <Avatar alt={buildPlainText(item?.chief_complaints)} src="." sx={{ width: 48, height: 48 }} />
      {!collapse && (
        <>
          <ListItemText
            sx={{ ml: 2 }}
            primary={buildPlainText(item?.chief_complaints)}
            primaryTypographyProps={{
              noWrap: true,
              variant: 'subtitle2',
            }}
            secondary={item?.screened_by}
          />

          <Stack alignItems="flex-end" sx={{ ml: 2, height: 44 }}>
            <Typography
              noWrap
              variant="body2"
              component="span"
              sx={{
                mb: 1.5,
                fontSize: 12,
                color: 'text.disabled',
              }}
            >
              {fDate(item?.created_at)}
            </Typography>
          </Stack>
        </>
      )}
    </ListItemButton>
  );
}

ScreeningNavItem.propTypes = {
  collapse: PropTypes.bool,
  item: PropTypes.object,
  index: PropTypes.number,
  setScreeningId: PropTypes.func,
  selected: PropTypes.bool,
};
